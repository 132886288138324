import React from 'react';

function About() {
  return (
    <div className="container mt-5">
      <h1>About Us</h1>
      <p>A2 Trading Group is dedicated to providing top-tier financial advice and investment options. With a focus on building long-lasting relationships based on transparency and reliability, we are committed to achieving the best outcomes for our investors. Our team of experts leverages years of industry experience to guide our clients through their investment journey.</p>
    </div>
  );
}

export default About;
