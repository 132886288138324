import React from 'react';



const info = () => {
  return <svg data-tooltip="tooltip text" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
  </svg>
}




function InvestmentOptions() {
  return (
    <div className="container mt-5">
      <h1>Investment Plans</h1>
      <div className='mt-3'>
        <h3 >1.Basic Plan {info()}</h3>
        <div className="table-responsive">
          <table class="table">
            <thead class="table-dark">
              <tr>
                <th>Month</th>
                <th>30,000</th>
                <th>20,000</th>
                <th>15,000</th>
                <th>10,000</th>
                <th>5,000</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Month 1 <br /> 15 Days Return</td>
                <td> 32.5% - 9750<br /> 32.5% - 9750</td>
                <td>32.5% - 6500<br />32.5% - 6500</td>
                <td>32.5% - 4875<br />32.5% - 4875</td>
                <td>32.5% - 3250<br />32.5% - 3250</td>
                <td>32.5% - 1625<br />32.5% - 1625</td>
              </tr>
              <tr>

                <td>Month 2 <br /> 15 Days Return</td>
                <td>32.5% - 9750<br />32.5% - 9750</td>
                <td>32.5% - 6500<br />32.5% - 6500</td>
                <td>32.5% - 4875<br />32.5% - 4875</td>
                <td>32.5% - 3250<br />32.5% - 3250</td>
                <td>32.5% - 1625<br />32.5% - 1625</td>
              </tr>
              <tr>

                <td>Month 3 <br /> 15 Days Return</td>
                <td>50% - 15000<br />50% - 15000</td>
                <td>50% - 10000<br />50% - 10000</td>
                <td>50% - 7500<br />50% - 7500</td>
                <td>50% - 5000<br />50% - 5000</td>
                <td>50% - 2500<br />50% - 2500</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total Return</td>
                <td>130%</td>
                <td>130%</td>
                <td>130%</td>
                <td>130%</td>
                <td>130%</td>
              </tr>
              <tr>
                <td>Total Capital Return</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
              </tr>
            </tfoot>
          </table>

        </div>
        <div class="accordion mt-2" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Plan Details
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul class="list-group">
                  <li class="list-group-item">We are giving 65% return for 2 Month by 15days / 15days</li>
                  <li class="list-group-item">Your capital will be return to you by ending of the 3rd month</li>
                </ul>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className='mt-3'>
        <h3 >2. Medium Plan</h3>
        <div class="alert alert-dark" role="alert">
          We are giving 65% return for 2 Month by 15days / 15days.
        </div>
        <div className="table-responsive">
          <table class="table">
            <thead class="table-dark">
              <tr>
                <th>Month</th>
                <th>30,000</th>
                <th>20,000</th>
                <th>15,000</th>
                <th>10,000</th>
                <th>5,000</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Month 1 <br /> 15 Days Return</td>
                <td> 32.5% - 9750<br /> 32.5% - 9750</td>
                <td>32.5% - 6500<br />32.5% - 6500</td>
                <td>32.5% - 4875<br />32.5% - 4875</td>
                <td>32.5% - 3250<br />32.5% - 3250</td>
                <td>32.5% - 1625<br />32.5% - 1625</td>
              </tr>
              <tr>

                <td>Month 2 <br /> 15 Days Return</td>
                <td>32.5% - 9750<br />32.5% - 9750</td>
                <td>32.5% - 6500<br />32.5% - 6500</td>
                <td>32.5% - 4875<br />32.5% - 4875</td>
                <td>32.5% - 3250<br />32.5% - 3250</td>
                <td>32.5% - 1625<br />32.5% - 1625</td>
              </tr>
              <tr>

                <td>Month 3 <br /> 15 Days Return</td>
                <td>50% - 15000<br />50% - 15000</td>
                <td>50% - 10000<br />50% - 10000</td>
                <td>50% - 7500<br />50% - 7500</td>
                <td>50% - 5000<br />50% - 5000</td>
                <td>50% - 2500<br />50% - 2500</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total Return</td>
                <td>130%</td>
                <td>130%</td>
                <td>130%</td>
                <td>130%</td>
                <td>130%</td>
              </tr>
              <tr>
                <td>Total Capital Return</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
              </tr>
            </tfoot>
          </table>


        </div>
      </div>
    </div >
  );
}

export default InvestmentOptions;
