import React from 'react';

function Home() {
  return (
    <div className="App">
  

    <header className="bg-primary text-white text-center py-5">
      <h1>Welcome to A2 Trading Group</h1>
      <p>Your partner in investment solutions.</p>
    </header>

    <section className="py-5">
      <div className="container">
        <h2 className="text-center mb-4">Why Choose Us?</h2>
        <div className="row">
          <div className="col-md-4">
            <h3>Expertise</h3>
            <p>Dedicated to providing top-tier financial advice and investment options.</p>
          </div>
          <div className="col-md-4">
            <h3>Trust</h3>
            <p>Building long-lasting relationships with our clients based on transparency and reliability.</p>
          </div>
          <div className="col-md-4">
            <h3>Results</h3>
            <p>Committed to achieving the best outcomes for our investors.</p>
          </div>
        </div>
      </div>
    </section>

    <footer className="bg-light text-center text-lg-start">
      <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
        © 2024 A2 Trading Group
      </div>
    </footer>
  </div>
  );
}

export default Home;
